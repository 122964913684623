import React, { useEffect, useState } from "react";
import searchIcon from "../../../assets/images/search-icon.svg";
import { Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDetectClickOutside } from "react-detect-click-outside";
// import Pagination from "../../../components/Pagination";
// import Pagination from 'react-bootstrap/Pagination';
import xmark from "../../../assets/images/xmark.svg";
import useApi from "../../../helpers/apiHelper/requestHelper";
import RenderLinks from "../../../components/links/RenderLinks";
import NoDataGif from "../../../assets/images/no_data.gif"
import CustomPagination from "../../../components/CustomPagination";
import { formatTime, simplifyTradingVolume } from "../../../helpers/helper";
import Table from 'react-bootstrap/Table';

export default function TradeHistory() {
    const api = useApi();
    const [show, setShow] = useState(false);
    const [startDate, setStartDate] = useState();
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [history, setHistory] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedCommunities, setSelectedCommunities] = useState([]);
    const ref = useDetectClickOutside({ onTriggered: () => setShow(false) });

    useEffect(() => {
        const filterHistory = () => {
            let filtered = [...history];

            if (selectedCommunities.length > 0) {
                filtered = filtered.filter((e) =>
                    selectedCommunities.includes(e.community)
                );
            }

            if (startDate) {
                filtered = filtered.filter(
                    (e) =>
                        new Date(startDate).toLocaleDateString() ===
                        new Date(e?.timestamp).toLocaleDateString()
                );
            }

            setFilteredHistory(filtered);
        };

        filterHistory();
    }, [selectedCommunities, startDate, history]);

    const getPercentage = (value) => {
        let final = parseFloat(value)

        if (isNaN(final)) {
            return "0.00";
        }

        if (final === "-0.00") {
            return "0.00";
        } else {
            return final;
        }
    };

    useEffect(() => {
        const getTradeHistory = async () => {
            const response = await api("get", "users/getTradeHistory");
            if (response?.status) {
                setHistory(response?.data);
            }
        };

        getTradeHistory();
    }, []);

    return (
        <div className="row justify-content-center h-100 p-top-on-mobile">
            <div className="col-lg-12">
                <div className="connection" style={{ paddingTop: "42px" }}>

                    <div className=" mx-auto">

                        <div className="page_title d-flex align-items-center justify-content-between flex-nowrap mb-3">
                            <h2 className="tradinghistporyTitle">
                                Trading history
                            </h2>
                            <div className="custom-dropdown d-flex" ref={ref}>

                                <button
                                    className="dropdown-toggle"
                                    onClick={() => setShow(!show)}
                                >
                                    <img
                                        className="filter-icon"
                                        alt="searchIcons"
                                        src={searchIcon}
                                    />
                                </button>

                                {show && (
                                    <div className="dropdown-menu">
                                        <Accordion defaultActiveKey={""}>
                                            <div className="clear-all">
                                                <text>Filters</text>
                                                <span
                                                    onClick={() => {
                                                        setStartDate();
                                                        setSelectedCommunities(
                                                            []
                                                        );
                                                    }}
                                                >
                                                    Clear All
                                                </span>
                                            </div>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    Trade Date
                                                </Accordion.Header>
                                                <Accordion.Body className="d-flex align-items-center justify-content-around">
                                                    <DatePicker
                                                        className="px-2 py-2 mt-2"
                                                        selected={startDate}
                                                        onChange={(date) => {
                                                            setStartDate(date);
                                                        }}
                                                        placeholderText="Select Date"
                                                        maxDate={new Date()}
                                                    />
                                                    <span
                                                        className="ms-2 cursor-pointer"
                                                        onClick={() =>
                                                            setStartDate()
                                                        }
                                                    >
                                                        <img
                                                            className="xmark"
                                                            src={xmark}
                                                            alt="xmark"
                                                        />
                                                    </span>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <li></li>
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className="tradeHistory">

                            <Table striped className="billingTable TradeHistoryTable">
                                <thead>
                                    <tr>
                                        <th className='text-start ps-3'>Asset <span className="d-lg-none d-md-none">& Direction</span></th>
                                        <th className='text-start d-set'>Direction</th>
                                        <th>Closed</th>
                                        <th>PNL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(filteredHistory.length > 0
                                        ? filteredHistory
                                        : history
                                    )
                                        ?.filter((e) => {
                                            if (startDate) {
                                                if (
                                                    new Date(
                                                        startDate
                                                    ).toLocaleDateString() ===
                                                    new Date(
                                                        e?.timestamp
                                                    ).toLocaleDateString()
                                                ) {
                                                    return e;
                                                }
                                            } else {
                                                return e;
                                            }
                                        })
                                        ?.filter((e, index) => {
                                            if (
                                                index < page * 10 &&
                                                index > page * 10 - 11
                                            ) {
                                                return e;
                                            }
                                        })
                                        ?.map((item, index) => (
                                            <tr key={index}>
                                                <td className='text-start ps-3'>
                                                    <p className="border-right text-truncate pb-set">
                                                        {item?.pair}
                                                    </p>
                                                    <p className="d-lg-none d-md-none pt-0">
                                                        {item?.side}
                                                    </p>
                                                </td>
                                                <td className='text-start d-set'>
                                                    <p className="border-right">
                                                        {item?.side}
                                                    </p>
                                                </td>
                                                <td className="dark-text">
                                                    <p>
                                                        {(() => {
                                                            const date = new Date(
                                                                item?.timestamp
                                                            );
                                                            const day =
                                                                date.getDate();
                                                            const month =
                                                                date.getMonth() + 1; // Adding 1 because months are zero-based
                                                            const year =
                                                                date.getFullYear();
                                                            return `${month}.${day}.${year}`;
                                                        })()} <span>{formatTime(item?.timestamp)}</span>
                                                    </p>
                                                </td>
                                                <td className="pe-3 pe-lg-0 pe-md-0">
                                                    <div className="d-lg-flex d-md-flex align-items-center justify-content-start gap-2">
                                                        <div
                                                            className={`number d-flex align-items-center ${parseFloat(
                                                                parseFloat(
                                                                    item?.pnlPercentage || "0.00%"
                                                                )
                                                            ) < 0
                                                                ? "active"
                                                                : ""
                                                                }`}
                                                        // style={{ fontSize: "16px", paddingLeft: "7px" }}
                                                        >
                                                            <h4>
                                                                {simplifyTradingVolume(getPercentage(
                                                                    item?.pnlPercentage || "0.00"
                                                                ))}%

                                                            </h4>
                                                            <span className="text-green ms-2 d-none d-lg-block d-md-block">
                                                                /
                                                            </span>
                                                        </div>
                                                        {/* <span className="text-green d-lg-block d-md-block d-none">
                                                            /
                                                        </span> */}
                                                        <div
                                                            className={`number ${parseFloat(
                                                                parseFloat(
                                                                    item?.realizedPnl ||
                                                                    "0.00%"
                                                                )
                                                            ) < 0
                                                                ? "active"
                                                                : ""
                                                                }`}
                                                        >
                                                            <h4>
                                                                ${simplifyTradingVolume(getPercentage(
                                                                    item?.realizedPnl || "0.00"
                                                                ))}

                                                            </h4>
                                                        </div>
                                                    </div>
                                                </td>

                                            </tr>
                                        ))}
                                    {(filteredHistory.length === 0 && history.length === 0) && (
                                        <tr>
                                            <td colSpan="4" className="text-center"><p className="text-center">There's no trading history yet</p></td>
                                        </tr>
                                    )}
                                </tbody >
                            </Table >

                        </div>
                        <div className="tradeHistoryMbl d-none">
                            {(filteredHistory.length > 0
                                ? filteredHistory
                                : history
                            )
                                ?.filter((e) => {
                                    if (startDate) {
                                        if (
                                            new Date(
                                                startDate
                                            ).toLocaleDateString() ===
                                            new Date(
                                                e?.timestamp
                                            ).toLocaleDateString()
                                        ) {
                                            return e;
                                        }
                                    } else {
                                        return e;
                                    }
                                })
                                ?.filter((e, index) => {
                                    if (
                                        index < page * 10 &&
                                        index > page * 10 - 11
                                    ) {
                                        return e;
                                    }
                                })
                                ?.map((item, index) => (
                                    <div className="card" key={index}>
                                        <div className="content">
                                            <div>
                                                <span className="fs12 text-uppercase">
                                                    {item?.exchange}
                                                </span>
                                                <p className=" text-truncate">
                                                    {item?.pair}
                                                </p>
                                            </div>
                                            <div className="dateShort">
                                                <span>
                                                    {(() => {
                                                        const date = new Date(
                                                            item?.timestamp
                                                        );
                                                        const day =
                                                            date.getDate();
                                                        const month =
                                                            date.getMonth() + 1; // Adding 1 because months are zero-based
                                                        const year =
                                                            date.getFullYear();
                                                        return `${month}.${day}.${year}`;
                                                    })()}
                                                </span>
                                                <p className="">
                                                    {item?.side}
                                                </p>
                                            </div>
                                        </div>
                                        <span className="borderLine"></span>
                                        <div
                                            className={`number ${parseFloat(
                                                parseFloat(
                                                    item?.pnlPercentage || "0.00%"
                                                )
                                            ) < 0
                                                ? "active"
                                                : ""
                                                }`}
                                            style={{ fontSize: "16px", paddingLeft: "7px" }}
                                        >
                                            <h4>
                                                {(() => {
                                                    const simplifiedValue = getPercentage(item?.pnlPercentage || "0.00")
                                                    return typeof simplifiedValue === "string" ? simplifiedValue : simplifiedValue.toFixed(2)
                                                })()}%

                                            </h4>
                                        </div>
                                        <div
                                            className={`number ${parseFloat(
                                                parseFloat(
                                                    item?.realizedPnl ||
                                                    "0.00%"
                                                )
                                            ) < 0
                                                ? "active"
                                                : ""
                                                }`}
                                        >
                                            <h4>
                                                ${simplifyTradingVolume(getPercentage(
                                                    item?.realizedPnl || "0.00"
                                                ))}

                                            </h4>
                                        </div>
                                    </div>
                                ))}
                        </div>

                        <div className="d-flex justify-content-end mt-41">
                            <CustomPagination
                                setPage={setPage}
                                page={page}
                                filtered={filteredHistory}
                                items={history}
                            />
                            {/* <Pagination className="custom-pagination">
                                    <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
                                    <Pagination.Prev
                                        onClick={() => page > 1 && setPage(page - 1)}
                                        disabled={page === 1}
                                    />

                                    {pages.length > 0 && pages.map((pageNumber) => (
                                        <Pagination.Item
                                            key={pageNumber}
                                            active={page === pageNumber}
                                            onClick={() => setPage(pageNumber)}
                                        >
                                            {pageNumber}
                                        </Pagination.Item>
                                    ))}

                                    <Pagination.Next
                                        onClick={() => page < pages.length && setPage(page + 1)}
                                        disabled={page === pages.length}
                                    />
                                    <Pagination.Last
                                        onClick={() => setPage(pages.length)}
                                        disabled={page === pages.length}
                                    />
                                </Pagination> */}
                        </div>

                    </div>
                </div>
                <RenderLinks page="performanceHistory" />
            </div>
        </div >
    );
}
