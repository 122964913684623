import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import download from "../../../assets/images/download.svg";
import CustomPagination from "../../../components/CustomPagination";
import { isMobile } from 'react-device-detect';
import useApi from '../../../helpers/apiHelper/requestHelper';

function BillingHistory({ notShowPaymentDetail }) {
    const [getInvoice, setGetInvoice] = useState([]);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [page, setPage] = useState(1); // Current page
    const itemsPerPage = 10; // Number of invoices per page
    const api = useApi();



    console.log(getInvoice, page, filteredInvoices, "dattttaaaaa")
    useEffect(() => {
        const fetchInvoices = async () => {
            const response = await api("get", "users/getInvoices");

            if (response?.data?.data) {
                // Filter data to include only the required fields
                const filteredData = response.data.data.map((invoice) => ({
                    amount_paid: invoice.amount_paid,
                    period_end: invoice.period_end,
                    period_start: invoice.period_start,
                    paid: invoice.paid,
                }));

                setGetInvoice(filteredData);
                setFilteredInvoices(filteredData.slice(0, itemsPerPage)); // Set initial invoices for the first page
            }
        };

        fetchInvoices();
    }, []);

    // Update displayed invoices based on the current page
    useEffect(() => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setFilteredInvoices(getInvoice.slice(startIndex, endIndex));
    }, [page, getInvoice]);

    return (
        <>{
            !notShowPaymentDetail &&
            <div className='billingBox'>
                <h2 className='billingHeding'>Billing history</h2>
                <Table striped className="billingTable">
                    <thead>
                        <tr>
                            {isMobile ? <th className='text-start ps-3'>Plan</th> : <th className='text-start ps-3'>Subscription</th>}
                            <th className='text-start'>Month</th>
                            <th>Status</th>
                            <th>Renews</th>
                            <th>Invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredInvoices && filteredInvoices.length > 0 ? (
                            filteredInvoices.map((invoice, index) => {
                                const periodStartDate = new Date(invoice.period_start * 1000);
                                const periodEndDate = new Date(invoice.period_end * 1000);
                                const month = periodStartDate.toLocaleString('default', { month: 'long' });

                                return (
                                    <tr key={index}>
                                        <td className='text-start ps-3'>${(invoice?.amount_paid / 100).toFixed(2)}</td>
                                        <td className='text-start'>{month}</td>
                                        <td className={invoice.paid ? 'text-green' : 'text-red'}>
                                            {invoice?.paid ? 'PAID' : 'UNPAID'}
                                        </td>
                                        <td>
                                            {isMobile ? periodEndDate.toLocaleDateString() : `${periodStartDate.getMonth() + 1}/${periodEndDate.getDate()}`}
                                        </td>
                                        <td>
                                            <button type='button' className='download-btn'>
                                                <img src={download} alt='' />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">No Invoices Found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <div className='d-flex justify-content-lg-end justify-content-md-end justify-content-center pt-2'>
                    <CustomPagination
                        page={page}
                        setPage={setPage}
                        items={getInvoice.length}
                        filtered={getInvoice}
                    />
                </div>
            </div>
        }
        </>
    );
}

export default BillingHistory;
