import React, { useEffect, useState } from "react";
import searchIcon from "../../../assets/images/search-icon.svg";
import { useDetectClickOutside } from "react-detect-click-outside";
// import Pagination from "../../../components/Pagination";
import CustomPagination from "../../../components/CustomPagination"
import Cross from "../../../assets/images/cancel.svg";
import Message from "../../../assets/images/Envelope.svg";
import DeleteMember from "../../../components/modals/DeleteMember";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { updateUser } from "../../../redux/users/usersSlice";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";
import NoDataGif from "../../../assets/images/no_data.gif"
import { simplifyTradingVolume } from "../../../helpers/helper";
import { Table } from "react-bootstrap";

export default function MembersOverview({ setTab, setUser }) {
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [activeTab, setActiveTab] = useState("name");
    const [selectedSubscription, setSelectedSubscription] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [filterData, setFilterData] = useState(null)
    const [page, setPage] = useState(1);
    const user = useSelector((value) => value?.user?.user);
    const api = useApi();
    const [stats, setStats] = useState({});
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");
    const ref = useDetectClickOutside({
        onTriggered: () => {
            setShow(false)
            setSearchTerm("")
        }
    });
    const participants = useSelector(
        (state) => state?.user?.user?.leaderCommunity?.participants
    );
    const leaderCommunity = useSelector(
        (state) => state?.user?.user?.leaderCommunity
    );

    const getUserStats = async () => {
        const response = await api("get", "stats/index");
        if (response?.status) {
            setStats(response?.data);
        }
        // else {
        //     toastify(response?.message);
        // }
    };

    useEffect(() => {
        const fetchBalances = async () => {
            const response = await api("get", "users/getMembersBalance");
            if (response?.status) {
                dispatch(updateUser(response?.data));
            }
            setFilterData(response?.data?.leaderCommunity?.participants)

            console.log(response, "memberresponse")
        };

        console.log(filterData, "filterdata")

        fetchBalances();
        getUserStats()
    }, []);
    console.log(filterData, "length")

    useEffect(() => {
        if (!Array.isArray(participants)) {
            return;
        }
        let filtered = [...participants];

        filtered = filtered.filter((e) =>
            e?.user?.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
        );
        setFilteredParticipants(filtered);
    }, [searchTerm, participants]);

    const noAction = () => {
        setShowModal(false);
    };

    const handleMessage = (val) => {
        setTab("message");
        setUser(val);
    };

    const yesAction = async () => {
        setShowModal(false);
        dispatch(setLoading(true));
        const response = await api("post", "users/unsubscribe", {
            sessionId: selectedSubscription,
            communityId: leaderCommunity._id,
            userId: selectedUser,
            leader: true,
        });
        if (response?.status) {
            toastify("User unsubscribed successfully.", "success");
            dispatch(updateUser(response?.data?.user));
        } else {
            toastify(response?.message);
        }

        dispatch(setLoading(false));

        setSelectedSubscription("");
        setSelectedUser("");
    };

    return (
        <div className="row justify-content-center h-100">

            <div className="col-lg-12 d-flex flex-column">
                <div className="connection">
                    <div className="py-40">
                        <div className="subscriptionMember-topStats">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12 mb-4 mb-lg-0 mb-md-0">
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="title">
                                                Total Subscribers
                                            </p>
                                            <p className="desc">
                                                <span className="green-text me-1">
                                                    +{stats?.userGrowthInWeekNumber || 0} Subs
                                                </span>
                                                since last week
                                            </p>
                                        </div>
                                        <div className="col-6">
                                            <p className="numbers">
                                                {user?.leaderCommunity?.participants
                                                    ?.length || 0}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="title">
                                                Avg. membership
                                            </p>
                                            <p className="desc">
                                                In months
                                            </p>
                                        </div>
                                        <div className="col-6">
                                            <p className="numbers">
                                                {stats?.membersMonthAvg || 0}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="title mb-3">
                            <h3>Members</h3>
                        </div>

                        <div className="page_title d-flex align-items-center justify-content-end flex-nowrap mb-3">
                            <div className="custom-dropdown d-flex" ref={ref}>

                                <button
                                    className="dropdown-toggle position-unset"
                                    onClick={() => setShow(!show)}
                                >
                                    <img
                                        className="filter-icon"
                                        alt="searchIcons"
                                        src={searchIcon}
                                    />
                                </button>

                                {show && (
                                    <div className="dropdown-menu">
                                        <div className="membersSearch">
                                            <div className="tabs">
                                                <button
                                                    className={`tab ${activeTab === "name"
                                                        ? "active"
                                                        : ""
                                                        }`}
                                                    onClick={() =>
                                                        setActiveTab("name")
                                                    }
                                                >
                                                    Email
                                                </button>
                                                {/* <button
                                                    className={`tab ${activeTab === "tier"
                                                        ? "active"
                                                        : ""
                                                        }`}
                                                    onClick={() =>
                                                        setActiveTab("tier")
                                                    }
                                                >
                                                    Tier
                                                </button> */}
                                            </div>
                                            {activeTab === "name" && (
                                                <div>
                                                    {participants?.length > 0 ? (
                                                        <div className="tab-contents">
                                                            <input
                                                                type="text"
                                                                placeholder="Search email"
                                                                value={
                                                                    searchTerm
                                                                }
                                                                onChange={(e) =>
                                                                    setSearchTerm(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="ps-2 pe-sm-5 pe-2 py-3 text-center">
                                                            <h6 className="bold">
                                                                No Subscribers
                                                            </h6>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {activeTab === "tier" && (
                                                <div className="tab-contents">
                                                    <p>Tier: $99</p>
                                                    <p>
                                                        <strong>
                                                            Tier: $34
                                                        </strong>
                                                    </p>
                                                    <p>Tier: $23</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="membersOverview">

                            <Table striped className="billingTable">
                                <thead>
                                    <tr>
                                        <th className='text-start ps-3'>Email</th>
                                        <th className='text-start'>Account size</th>
                                        <th className='text-start'>Joined</th>
                                        {/* <th className='text-start'>Tier</th> */}
                                        <th className='text-start ps-5'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {participants &&
                                        participants
                                            .filter((e) =>
                                                e?.user?.email
                                                    ?.toLowerCase()
                                                    .includes(searchTerm.toLowerCase())
                                            )
                                            .slice((page - 1) * 10, page * 10)
                                            .length > 0 ? (
                                        participants
                                            .filter((e) =>
                                                e?.user?.email
                                                    ?.toLowerCase()
                                                    .includes(searchTerm.toLowerCase())
                                            )
                                            .slice((page - 1) * 10, page * 10)
                                            .map((filteredParticipant) => (
                                                <tr key={filteredParticipant.user._id}>
                                                    <td className="text-start ps-3 light text-limit">
                                                        {filteredParticipant?.user?.email}
                                                    </td>
                                                    <td className="text-start light">
                                                        {`$${simplifyTradingVolume(
                                                            (filteredParticipant?.binanceBalance?.balance || 0) +
                                                            (filteredParticipant?.binanceBalance?.coinFutureBalance || 0)
                                                        )}`}
                                                    </td>
                                                    <td className="text-start">
                                                        {(() => {
                                                            const date = new Date(filteredParticipant.date);
                                                            return `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;
                                                        })()}
                                                    </td>
                                                    {/* <td className="text-start">$199</td> */}
                                                    <td className="text-start d-display ps-5">
                                                        <button
                                                            type="button"
                                                            className="download-btn gapleft"
                                                            onClick={() => handleMessage(filteredParticipant?.user)}
                                                        >
                                                            <img src={Message} alt="message" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="download-btn gapright"
                                                            onClick={() => {
                                                                const _community = filteredParticipant.user.memberCommunities?.find(
                                                                    (f) => f.community === leaderCommunity._id.toString()
                                                                );
                                                                setSelectedSubscription(_community?.sessionId);
                                                                setSelectedUser(filteredParticipant.user._id);
                                                                setShowModal(true);
                                                            }}
                                                        >
                                                            <img src={Cross} alt="cross" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">No member Found</td>
                                        </tr>
                                    )}
                                </tbody>


                            </Table>
                            {/* {participants
                                ?.filter((e) =>
                                    e?.user?.email
                                        ?.toLowerCase()
                                        ?.includes(searchTerm.toLowerCase())
                                )
                                ?.filter((e, index) => {
                                    if (
                                        index < page * 10 &&
                                        index > page * 10 - 11
                                    ) {
                                        return e;
                                    }
                                })
                                ?.map((filteredParticipant) => (
                                    <div
                                        className="card memberContent align-items-lg-center align-items-md-center"
                                        key={filteredParticipant.user._id}
                                    >
                                        <div className="contentBlock align-items-end width-90">
                                            <div className="content width-60">
                                                <h5>
                                                    {
                                                        filteredParticipant
                                                            ?.user?.email
                                                    }
                                                </h5>
                                                <p className="mt-2">
                                                    {`Account size: $${simplifyTradingVolume(
                                                        (filteredParticipant
                                                            ?.binanceBalance
                                                            ?.balance || 0) + (filteredParticipant
                                                                ?.binanceBalance
                                                                ?.coinFutureBalance ||
                                                                0)
                                                    )}`}
                                                </p>
                                            </div>
                                            <div className="content">
                                                <p>Joined: {(() => { const d = new Date(filteredParticipant.date); return `${d.getMonth() + 1}.${d.getDate()}.${d.getFullYear()}`; })()}</p>
                                                {/* <h5 className="mt-2 text-end">Tier: $213</h5> */}
                            {/* </div>
                                        </div>

                                        <div className="number">
                                            <img
                                                onClick={() => {
                                                    let _community =
                                                        filteredParticipant.user.memberCommunities?.find(
                                                            (f) =>
                                                                f.community ===
                                                                leaderCommunity._id.toString()
                                                        );

                                                    setSelectedSubscription(
                                                        _community.sessionId
                                                    );
                                                    setSelectedUser(
                                                        filteredParticipant.user
                                                            ._id
                                                    );
                                                    setShowModal(true);
                                                }}
                                                src={Cross}
                                                alt="cross"
                                            />
                                            <img
                                                src={Message}
                                                alt="message"
                                                onClick={() =>
                                                    handleMessage(
                                                        filteredParticipant?.user
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                ))} */}
                        </div>


                        <div className="d-flex ms-auto justify-content-lg-end justify-content-md-end justify-content-center mt-41">
                            {/* <Pagination
                                    setPage={setPage}
                                    page={page}
                                    items={participants}
                                    filtered={filteredParticipants}
                                /> */}
                            <CustomPagination
                                setPage={setPage}
                                page={page}
                                items={participants}
                                filtered={filteredParticipants}
                            />
                        </div>


                    </div>
                </div>
            </div>

            {showModal && (
                <DeleteMember
                    noAction={noAction}
                    yesAction={yesAction}
                    text="Delete member?"
                />
            )}
            <RenderLinks page="membersOverview" />
        </div>
    );
}